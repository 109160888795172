import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../../baseUrl";
import { useSelector } from "react-redux";
import axios from "axios";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";

const MainLeadsForm = () => {
  let { id } = useParams();
  let param = id.split("_");

  let leadId = param[0];
  let accessType = param[param.length - 1];

  const navigate = useNavigate();

  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  console.log("state", userType);

  const [agents, setAgents] = useState([]);
  const [orderID, setOrderID] = useState("");
  const [date, setDate] = useState(
    `${
      new Date().getMonth() +
      1 +
      " / " +
      new Date().getDate() +
      " / " +
      new Date().getFullYear()
    }`
  );
  const [month, setMonth] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [brand, setBrand] = useState("");
  const [status, setStatus] = useState("");
  const [productType, setProductType] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [merchant, setMerchant] = useState("");
  const [agent, setAgent] = useState("");
  const dateD = new Date().getDate();
  const monthD = new Date().getMonth() + 1;
  var fullDate =
    new Date().getFullYear() +
    (String(monthD).length === 1 ? "-0" + monthD : "-" + monthD) +
    "-" +
    dateD;

  const [deliveryDate, setDeliveryDate] = useState(`${fullDate}`);
  console.log("fullDate", fullDate);

  const [comment, setComment] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  //notify states
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getSpecific();
  }, []);

  const getSpecific = async () => {
    const response = await axios
      .post(
        `${url}${
          accessType === "CREATE"
            ? "/leads/getSpecific"
            : "/convertedLeads/getSpecific"
        }`,
        { _id: leadId },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((response) => {
        let data = response.data.data.data;
        console.log(data);
        userType === "admin" || leadId == "00000000"
          ? setIsDisable(false)
          : setIsDisable(true);

        if (accessType === "CREATE") {
          setName(data.client_name);
          setEmail(data.email);
          setPhone(data.phone_number);
          setBrand(data.brand);
          setAgent(data.agent_name ? data.agent_name._id : "");
          setComment(data.comment);
        } else {
          //reaOnly and update
          setName(data.customer_name);
          setEmail(data.email);
          setPhone(data.phone_number);
          setBrand(data.brand);
          setStatus(data.lead_status);
          setProductType(data.product_type);
          setWordCount(data.word_count);
          setAmount(data.amount);
          setOrderID(data.order_id);
          setRemainingBalance(data.remaining_balance);
          setMerchant(data.merchant);
          setDate(data.date.split("T")[0]);
          setDeliveryDate(data.delivery_date.split("T")[0]);
          setAgent(data.agent ? data.agent._id : "");
          setComment(data.comment);

          if (accessType === "update") {
            setIsReadOnly(false);
          } else {
            setIsReadOnly(true);
          }
        }
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // const getAllUsers = async () => {
  //   try {
  //     setIsLoaded(true);
  //     const response = await axios
  //       .get(`${url}/auth/getAllUsers`, {
  //         headers: {
  //           Authorization: `Bearer ${state.token}`,
  //         },
  //       })
  //       .then((data) => {
  //         // setAgents([]);
  //         let users = data.data.data.data;
  //         users.filter((user) => {
  //           if (user.user_type === "sales_agent") {
  //             agents.push(user);
  //           }
  //         });

  //         setIsOpen(true);
  //         setMessage("Data Loaded!");
  //         setSeverity("success");

  //         setIsLoaded(false);
  //       });
  //   } catch (error) {
  //     console.log("error", error.message);
  //     setIsLoaded(false);
  //     setIsOpen(true);
  //     setMessage(error.message);
  //     setSeverity("error");
  //   }
  //   setTimeout(() => {
  //     setIsOpen(false);
  //   }, 2000);
  // };

  const getAllUsers = async () => {
    try {
      await axios
        .get(`${url}/auth/getAllUsers`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((response) => {
          console.log(
            "response",
            response.data.data.data.filter(
              (user) => user.user_type === "sales_agent"
            )
          );

          setAgents(
            response.data.data.data.filter(
              (user) => user.user_type === "sales_agent"
            )
          );
        });
    } catch (error) {}
  };
  const submitForm = async () => {
    setIsLoaded(true);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date();
    setMonth(monthNames[d.getMonth()]);
    const data =
      accessType === "CREATE"
        ? leadId == "00000000"
          ? {
              month: monthNames[d.getMonth()],
              customer_name: name,
              email: email,
              phone_number: phone,
              brand,
              lead_status: status,
              product_type: productType,
              word_count: Number(wordCount),
              amount: Number(amount),
              remaining_balance: Number(remainingBalance),
              merchant,
              agent,
              order_id: orderID,
              delivery_date: deliveryDate,
              comment,
            }
          : {
              month: monthNames[d.getMonth()],
              customer_name: name,
              email: email,
              phone_number: phone,
              brand,
              lead_status: status,
              product_type: productType,
              word_count: Number(wordCount),
              amount: Number(amount),
              remaining_balance: Number(remainingBalance),
              merchant,
              agent,
              order_id: orderID,
              delivery_date: deliveryDate,
              comment,
              parentId: leadId,
            }
        : {
            month: monthNames[d.getMonth()],
            customer_name: name,
            email: email,
            phone_number: phone,
            brand,
            lead_status: status,
            product_type: productType,
            word_count: Number(wordCount),
            amount: Number(amount),
            remaining_balance: Number(remainingBalance),
            merchant,
            agent,
            order_id: orderID,
            delivery_date: deliveryDate,
            comment,
            _id: leadId,
          };
    await axios
      .post(
        `${url}${
          accessType === "CREATE" ? "/convertedLeads" : "/convertedLeads/edit"
        }`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.data.data.success) {
          console.log(response.data.data.data);

          let id = response.data.data.data._id;

          navigate(`/convertedlead/${id}_readOnly`);

          console.log("true");
          setIsOpen(true);
          setMessage("Lead Converted Successfully!");
          setSeverity("success");
          setTimeout(() => {
            navigate("/convertedlead/list");
          }, 2000);
          // setIsLoaded(false);
        } else {
          setIsOpen(true);
          setMessage(response.data.data.message);
          setSeverity("error");
          console.log(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsOpen(true);
        setMessage(error.message);
        setSeverity("error");
      });
    setTimeout(() => {
      setIsOpen(false);
      setIsLoaded(false);
    }, 2000);
  };

  return (
    <>
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Paper sx={{ p: 3, mr: 2, ml: 2 }}>
            <Grid container sx={{ mt: 1, p: 3 }}>
              <Typography variant="h5" color="Primary">
                Convert Lead
              </Typography>
            </Grid>
            <Grid item className="Row" sx={{ p: 1 }}>
              <Grid item container md={9} xs={12}>
                <TextField
                  name="date"
                  label="Date"
                  margin="normal"
                  disabled={true}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientName"
                  label="Client Name"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={isReadOnly ? true : isDisable}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientEmail"
                  label="Client Email"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isReadOnly ? true : isDisable}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientPhone"
                  label="Client Phone No."
                  type="text"
                  margin="normal"
                  fullWidth
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={isReadOnly ? true : isDisable}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="brand"
                  label="Brand Name"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  disabled={isReadOnly ? true : isDisable}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="leadStatus"
                  label="Lead Status"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  disabled={isReadOnly}
                />

                {/* <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Lead Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={status}
                    style={{ textAlign: "left" }}
                    onChange={(e) => setStatus(e.target.value)}
                    fullWidth
                    label="Lead Status"
                    disabled={isReadOnly}
                  >
                    <MenuItem fullWidth value={"Fresh"}>
                      Fresh
                    </MenuItem>
                    <MenuItem fullWidth value={"Upsell"}>
                      Upsell
                    </MenuItem>
                    <MenuItem fullWidth value={"Data"}>
                      Data
                    </MenuItem>
                    <MenuItem fullWidth value={"Referral"}>
                      Referral
                    </MenuItem>
                    <MenuItem fullWidth value={"Existing"}>
                      Existing
                    </MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="productType"
                  label="Product / Service"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  disabled={isReadOnly}
                />

                {/* <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Product Service
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={productType}
                    style={{ textAlign: "left" }}
                    onChange={(e) => setProductType(e.target.value)}
                    fullWidth
                    label="Product Type"
                    disabled={isReadOnly}
                  >
                    <MenuItem fullWidth value={"Assignment"}>
                      Assignment
                    </MenuItem>
                    <MenuItem fullWidth value={"Exam"}>
                      Exam
                    </MenuItem>
                    <MenuItem fullWidth value={"Dissertation"}>
                      Dissertation
                    </MenuItem>
                    <MenuItem fullWidth value={"Presentation"}>
                      Presentation
                    </MenuItem>
                    <MenuItem fullWidth value={"CNBD"}>
                      CNBD
                    </MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="wordCount"
                  label="Word Count"
                  type="number"
                  margin="normal"
                  fullWidth
                  value={wordCount}
                  onChange={(e) => setWordCount(e.target.value)}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="amount"
                  label="Amount"
                  type="number"
                  margin="normal"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="remainingBalance"
                  label="Remaining Balance"
                  type="number"
                  margin="normal"
                  fullWidth
                  value={remainingBalance}
                  onChange={(e) => setRemainingBalance(e.target.value)}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="merchant"
                  label="Payment Merchant"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={merchant}
                  onChange={(e) => setMerchant(e.target.value)}
                  disabled={isReadOnly}
                />

                {/* <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Payment Merchant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={merchant}
                    style={{ textAlign: "left" }}
                    onChange={(e) => setMerchant(e.target.value)}
                    fullWidth
                    label="Payment Merchant"
                    disabled={isReadOnly}
                  >
                    <MenuItem fullWidth value={"Stripe"}>
                      Stripe
                    </MenuItem>
                    <MenuItem fullWidth value={"WISE"}>
                      WISE
                    </MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                {/* <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select Sales Agent
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={agent}
                    onChange={(e) => setAgent(e.target.value)}
                    style={{ textAlign: "left" }}
                    fullWidth
                    label="Select Sales Agent"
                    disabled={true}
                  >
                    {!isLoaded
                      ? agents
                        ? agents.map((item) => (
                            <MenuItem fullWidth value={item._id}>
                              {item.user_name}
                            </MenuItem>
                          ))
                        : ""
                      : []}
                  </Select>
                </FormControl> */}
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Agent
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={agent}
                    margin="normal"
                    label="Select Agent"
                    onChange={(e) => setAgent(e.target.value)}
                    disabled={isReadOnly ? true : isDisable}
                  >
                    {/* {console.log("allUsers", allUsers)} */}
                    {agents.length > 0 ? (
                      agents.map((user) => (
                        <MenuItem value={user._id}>{user.user_name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>none</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="orderId"
                  label="Order ID"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={orderID}
                  onChange={(e) => {
                    setOrderID(e.target.value);
                  }}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="deliveryDate"
                  label="Delivery Date"
                  type="date"
                  margin="normal"
                  fullWidth
                  value={deliveryDate}
                  onChange={(e) => {
                    setDeliveryDate(e.target.value);

                    console.log(e.target.value);
                  }}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={12} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="comment"
                  label="Comment"
                  multiline
                  rows={4}
                  type="text"
                  margin="normal"
                  fullWidth
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className="Row"
              sx={{ mt: 2 }}
              style={{
                gap: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item container md={1} xs={1}>
                <Button
                  type="clear"
                  style={{
                    border: `1px solid ${lightBackground}`,
                    color: `${lightBackground}`,
                    background: `${white}`,
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onClick={() => {
                    navigate("/convertedlead/list");
                  }}
                >
                  back
                </Button>
              </Grid>
              {accessType !== "readOnly" ? (
                <Grid item container md={1} xs={1}>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                    margin="normal"
                    fullWidth
                    disabled={isLoaded}
                  >
                    {isLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : accessType === "CREATE" ? (
                      "save"
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Paper>
        </Box>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default MainLeadsForm;
