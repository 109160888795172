import './App.css';
import Routers from './Pages/Routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/store";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

function App() {

  //disabling consoles
  console.log = () => { };

  return (
    // <ThemeProvider theme={primaryTheme}>
    <div className="App">
      <Provider store={store} >
        <PersistGate persistor={persistor}>
          <Routers />
        </PersistGate>
      </Provider>

    </div>
    // </ThemeProvider >
  );
}

export default App;
