import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import { url } from "../../baseUrl";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";

const AddUser = () => {
  let { id } = useParams();
  let param = id.split("_");
  let userId = param[0];
  const state = useSelector((state) => state.user.data);
  let accessType = param[param.length - 1];
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (event) => {
    setUserType(event.target.value);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getSpecific();
  }, []);

  const getSpecific = async () => {
    if (accessType !== "CREATE") {
      await axios
        .post(`${url}/auth/getSpecific`, { _id: userId },{
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((response) => {
          let data = response.data.data.data;
          setName(data.user_name);
          setEmail(data.user_email);
          setUserType(data.user_type);
          setPassword();
          setIsActive(data.active);
          accessType === "readOnly"
            ? setIsReadOnly(true)
            : setIsReadOnly(false);
        });
    }
  };

  const submitForm = async () => {
    if (email && userType && name) {
      setIsLoaded(true);
      var data =
        accessType === "CREATE"
          ? {
              user_email: email,
              user_type: userType,
              user_name: name,
              active: isActive,
              // password: password ? password : null,
            }
          : {
              user_email: email,
              user_type: userType,
              user_name: name,
              active: isActive,
              _id: userId,
              // password: password ? password : null,
            };

      if (password) {
        data = {
          ...data,
          password,
        };
      }
      await axios
        .post(
          `${url}${accessType === "CREATE" ? "/auth" : "/auth/edit"}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.data.success) {
            setIsOpen(true);
            setMessage(response.data.data.message);
            setSeverity("success");
            setIsReadOnly(true);
          } else {
            setIsOpen(true);
            setMessage(response.data.data.message);
            setSeverity("error");
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsOpen(true);
          setMessage(error.message);
          setSeverity("error");
        });

      setTimeout(() => {
        setIsOpen(false);
        setIsLoaded(false);
        navigate("/user/list");
      }, 3000);
    } else {
      setIsOpen(true);
      setMessage("fill up the required feilds");
      setSeverity("warning");

      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    }
  };

  return (
    <>
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Paper sx={{ p: 3, mr: 2, ml: 2 }}>
            <Grid container sx={{ mt: 2, p: 3 }}>
              <Typography variant="h5" color="Primary">
                User
              </Typography>
            </Grid>
            {/* <Grid item className="Row" sx={{ p: 1 }}>
            <Grid item container md={9} xs={12}>
              <TextField
                name="date"
                label="Date"
                // type="date"
                margin="normal"
                disabled={true}
                value={`${
                  new Date().getMonth() +
                  1 +
                  " / " +
                  new Date().getDate() +
                  " / " +
                  new Date().getFullYear()
                }`}
              />
            </Grid>
          </Grid> */}
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientName"
                  label="User Name"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={name}
                  disabled={isReadOnly}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientEmail"
                  label="User Email"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={email}
                  disabled={isReadOnly}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userType}
                    label="User Type"
                    style={{
                      textAlign: "left",
                    }}
                    onChange={handleChange}
                    disabled={isReadOnly}
                  >
                    <MenuItem value={"production_agent"}>
                      Production Agent
                    </MenuItem>
                    <MenuItem value={"sales_agent"}>Sales Agent</MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={isActive}
                    label="User Status"
                    style={{
                      textAlign: "left",
                    }}
                    onChange={(e) => {
                      setIsActive(e.target.value);
                    }}
                    disabled={isReadOnly}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>In-active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  margin="normal"
                  fullWidth
                  value={password}
                  disabled={accessType === "readOnly" ? true : false}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  margin="normal"
                  fullWidth
                  value={confirmPassword}
                  disabled={accessType === "readOnly" ? true : false}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              className="Row"
              sx={{ mt: 2 }}
              style={{
                gap: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item container md={1} xs={1}>
                <Button
                  type="clear"
                  style={{
                    border: `1px solid ${lightBackground}`,
                    color: `${lightBackground}`,
                    background: `${white}`,
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onClick={() => {
                    navigate("/user/list");
                  }}
                >
                  back
                </Button>
              </Grid>
              {accessType !== "readOnly" && !isReadOnly ? (
                <Grid item container md={1} xs={1}>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                    margin="normal"
                    fullWidth
                    disabled={isLoaded}
                  >
                    {isLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : accessType === "CREATE" ? (
                      "save"
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Paper>
        </Box>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default AddUser;
