import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { url } from "../../baseUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import { white, lightBackground } from "../../Assets/Theme/ThemeColors";
import CustomizedSnackbars from "../SnackBar/SnackBar";

export default function FormDialog({ open, leadId, handleClose }) {
  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  const userId = useSelector((state) => state.user.data.data.user._id);
  // console.log("user", state.data.user.user_type);
  const [message, setMessage] = React.useState("");
  const [messageBy, setMessageBy] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [attachment, setAttachment] = React.useState([]);
  const [date, setDate] = React.useState();

  const [isOpen, setIsOpen] = React.useState(false);
  const [NotMessage, setNotMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [isLoaded, setIsLoaded] = React.useState(false);

  const selectStatus =
    userType != "production_agent"
      ? [
          {
            name: "New Order",
            value: "NewOrder",
          },
          {
            name: "Revision",
            value: "Revision",
          },
        ]
      : [
          {
            name: "Working",
            value: "Working",
          },
          {
            name: "Delivered",
            value: "Delivered",
          },
        ];

  const handleSubmit = async () => {
    if ((message, status)) {
      setIsLoaded(true);
      // console.log("comment", message, messageBy, status, attachment);
      const formData = new FormData();
      formData.append("attachment", attachment);
      formData.append("message", message);
      formData.append("order_status", status);
      formData.append("productionId", leadId);
      formData.append("CommentBy", userId);
      formData.append("link", url.split("/a")[0]);
      var attachmentArray = [];
      attachmentArray.push(attachment);
      var data = {
        message,
        attachment: attachmentArray,
        order_status: status,
        productionId: leadId,
      };
      console.log(data);
      await axios
        .post(`${url}/commments/addNewComment`, formData, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(function (response) {
          console.log("response", response);
          setIsLoaded(false);
          setIsOpen(true);

          if (response.data.data.success) {
            setSeverity("success");
            setNotMessage(response.data.data.message);
          } else {
            setSeverity("error");
            setNotMessage(response.data.data.message);
          }
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoaded(false);
          setIsOpen(true);
          setNotMessage(error.message);
          setSeverity("error");
          setTimeout(() => {
            handleClose();
          }, 2000);
        });
    } else {
      setIsOpen(true);
      setNotMessage("fill the required feilds");
      setSeverity("error");
    }
    setTimeout(() => {
      setMessage("");
      setStatus("");
      setIsOpen(false);
      setIsLoaded(false);
    }, 2000);
  };
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
      Open form dialog
    </Button> */}
      <Dialog open={open}>
        <DialogTitle>Add new message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <Grid md={6} xs={12}>
            <TextField
              name="message"
              label="Enter Your Message"
              type="text"
              margin="normal"
              fullWidth
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>
          <Grid md={6} xs={12}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                margin="normal"
                label="Select Status"
                onChange={(e) => setStatus(e.target.value)}
                // disabled={disabledAll}
              >
                {selectStatus.map((opt) => (
                  <MenuItem value={opt.value}>{opt.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              name="status"
              label="Select Status"
              margin="normal"
              fullWidth
              type="text"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            /> */}
          </Grid>
          <Grid md={6} xs={12}>
            {/* <form>
              <h1>React File Upload</h1> */}
            <Input
              name="status"
              label="Select Status"
              margin="normal"
              fullWidth
              variant="outlined"
              type="file"
              // value={attachment[0].name }
              onChange={(e) => {
                setAttachment(e.target.files[0]);
              }}
            />
            {/* <button type="submit">Upload</button>
            </form> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="clear"
            style={{
              border: `1px solid ${lightBackground}`,
              color: `${lightBackground}`,
              background: `${white}`,
            }}
            variant="outlined"
            margin="normal"
            onClick={() => {
              setMessage("");
              setStatus("");
              handleClose();
            }}
          >
            back
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            variant="contained"
            style={{
              background: `${lightBackground}`,
              color: `${white}`,
            }}
            margin="normal"
            disabled={isLoaded}
          >
            {isLoaded ? (
              <span>
                {" "}
                <CircularProgress
                  style={{
                    color: `${white}`,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </span>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={NotMessage}
      />
    </div>
  );
}
