import * as React from "react";
import "./drawer.css";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { secondaryListItems } from "./listItems";
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../baseUrl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // Popover,
} from "@mui/material";
import { Popover } from "antd";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  darkBackground,
  darkButton,
  dimBackground,
  header,
  lightBackground,
  lightButton,
  lightText,
  navbar,
  white,
} from "../../Assets/Theme/ThemeColors";
import BasicPopover from "../Popover/PopOver";
import PopOver from "../Popover/PopOver";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/authAction";
import logo from "../../Assets/images/logoLight.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const Dashboard = () => {
  const [open, setOpen] = React.useState(false);
  const [mainListItems, setMainListItems] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [openPopover, setOpenPopover] = React.useState(false);
  const name = useSelector((state) =>
    state?.user?.data?.data?.user?.user_name?.toUpperCase()
  );
  const accessType = useSelector(
    (state) => state.user.data.data.user.user_type
  );

  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleState = (data) => {
    data = data.filter((item) => {
      if (item.accessType.includes(accessType)) {
        return item;
      }
    });
    // console.log(data,"item")
    setMainListItems(data);
  };
  React.useEffect(() => {
    getMenus();
  }, []);

  const getMenus = async () => {
    try {
      setIsLoaded(false);
      await axios
        .get(`${url}/menu`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((data) => {
          handleState(data.data.data.data);
        })
        .then(() => {
          setTimeout(() => {
            setIsLoaded(true);
          }, 500);
        });
    } catch (error) {
      setIsLoaded(true);
      console.log(error.message);
    }
  };

  const userLogout = () => {
    logout(dispatch);
    navigate("/signin");
  };

  const content = (
    <div onClick={userLogout} style={{ zIndex: 99999 }}>
      <p>Logout</p>
      {/* <Link
        to="/signin"
        //  style={{textDecoration :'none' , cursor:'pointer'}}
      > */}

      {/* </Link> */}
    </div>
  );

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", p: 0 }}>
        {isLoaded ? (
          <>
            <CssBaseline />
            <AppBar color="primary" position="absolute" open={open}>
              <Toolbar
                sx={{
                  // pr: "24px", // keep right padding when drawer closed
                  background: `${header}`,
                  color: `${white}`,
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    // marginRight: "36px",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h6"
                  color="primary"
                  noWrap
                  sx={{
                    flexGrow: 1,
                    color: `${white}`,
                    display: "flex",
                    justifyContent: "flex-start",
                    ml: 5,
                  }}
                >
                  <img
                    src={logo}
                    height="20px"
                    width="120px"
                    alt="logo"
                  ></img>
                </Typography>
                {/* <Typography
                  component="h1"
                  variant="h6"
                  color="primary"
                  noWrap
                  sx={{
                    flexGrow: 1,
                    color: `${white}`,
                    display: "flex",
                    justifyContent: "flex-end",
                    ml: 5,
                  }}
                >
                  Admin
                </Typography> */}
                {/* <PopOver name={name}/> */}
                <div className="demo">
                  <div style={{ clear: "both", whiteSpace: "nowrap" }}>
                    <Popover
                      placement="bottomRight"
                      // title={text}
                      content={content}
                      trigger="click"
                    >
                      <span style={{ cursor: "pointer", width: "100px" }}>
                        <Typography
                        // component="h5"
                        // //  variant="h6"
                        >
                          {name}
                          <KeyboardArrowDownIcon />
                        </Typography>
                      </span>
                    </Popover>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav">
                <React.Fragment>
                  {mainListItems.map((mn) => (
                    <Link
                      to={mn.menuUrl}
                      style={{
                        textDecoration: "none",
                        color: `${lightText}`,
                        background: `${navbar}`,
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <img alt="image" height="22px" src={mn.icon} />
                        </ListItemIcon>
                        <ListItemText primary={mn.menuName} />
                      </ListItemButton>
                    </Link>
                  ))}
                </React.Fragment>
                {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
              </List>
            </Drawer>
            <Box
              component="main"
              style={{
                width: "100%",
                maxWidth: "100%",
                backgroundColor: `${dimBackground}`,
                flexGrow: 1,
                minHeight: "100vh",
                maxHeight: "100vh",
                overflow: "hidden",
              }}
            >
              <Toolbar />
              <Container
                style={{ width: "100%", maxWidth: "100%", padding: "0px" }}
              >
                <Outlet />
              </Container>
            </Box>{" "}
          </>
        ) : (
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              background: `${navbar}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{
                height: "50px",
                width: "50px",
                color: `${white}`,
                marginRight: "10px",
              }}
            />
            <Typography variant="h5" color={`${white}`}>
              <img
                src={logo}
                height="40px"
                width="220px"
                alt="logo"
              ></img>
            </Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
