import axios from "axios";
import React, { useEffect, useState } from "react";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import { url } from "../../baseUrl";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import { useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const CustomerList = () => {
  const state = useSelector((state) => state.user.data);

  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  let navigate = useNavigate();
  // const navigate = useNavigate();

  const columns = [
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    },
    {
      label: "Customer Name",
      field: "cust_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Customer Email",
      field: "cust_email",
      sort: "asc",
      width: 270,
    },
    {
      label: "Customer Number",
      field: "cust_number",
      sort: "asc",
      width: 200,
    },
    {
      label: "Country",
      field: "country",
      sort: "asc",
      width: 100,
    },
    {
      label: "Created By",
      field: "created_by",
      sort: "asc",
      width: 150,
    },
  ];

  useEffect(() => {
    getAllCustomers();
  }, []);

  const getAllCustomers = async () => {
    try {
      setIsLoaded(true);
      const response = await axios
        .get(`${url}/customers/getAllCustomers`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              created_by: item.created_by ? item.created_by.user_name : "",
              action: (
                <>
                  <span
                    title="view"
                    onClick={() => navigate(`/customer/${item._id}_readOnly`)}
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  <span
                    onClick={() => navigate(`/customer/${item._id}_update`)}
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    title="edit"
                  >
                    <CreateIcon />
                  </span>
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");

          setIsLoaded(false);
        });
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5">Customers</Typography>
          </Grid>
          <Grid xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate(`/customer/00000000000_CREATE`)}
              variant="contained"
              style={{ background: `${lightBackground}`, color: `${white}` }}
            >
              Add new customer
            </Button>
          </Grid>
          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default CustomerList;
