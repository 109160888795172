import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../baseUrl";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  darkButton,
  lightBackground,
  lightText,
  white,
} from "../../Assets/Theme/ThemeColors";
import { useSelector } from "react-redux";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";

const MainLeadsList = () => {
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-2`)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 30)
  );

  const [isSheetLoaded, setIsSheetLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  let navigate = useNavigate();
  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  console.log(state.data.user.user_type);

  const columns = [
    {
      label: "Action",
      field: "action",
      sort: "asc",
      style: "width : '300px'",
      width: 650,
    },
    {
      label: "Month",
      field: "month",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 270,
    },
    {
      label: "Time",
      field: "time",
      sort: "asc",
      width: 270,
    },
    {
      label: "Client Name",
      field: "client_name",
      sort: "asc",
      width: 200,
    },
    // {
    //   label: "Email Address",
    //   field: "email",
    //   sort: "asc",
    //   width: 100,
    // },
    {
      label: "Phone Number",
      field: "phone_number",
      sort: "asc",
      width: 150,
    },
    {
      label: "Payment Status",
      field: "payment_status",
      sort: "asc",
      width: 100,
    },
    {
      label: "Brand",
      field: "brand",
      sort: "asc",
      width: 100,
    },

    {
      label: "Agent Name",
      field: "agentName",
      sort: "asc",
      width: 100,
    },
    {
      label: "Platform",
      field: "platform",
      sort: "asc",
      width: 100,
    },
    
    // {
    //   label: "Calling Comments",
    //   field: "calling_comment",
    //   sort: "asc",
    //   width: 100,
    // },
    // {
    //   label: "Comments",
    //   field: "comment",
    //   sort: "asc",
    //   width: 100,
    // },
  ];

  useEffect(() => {
    getAllMainLeads();
  }, []);

  const getFilterMainLeads = async () => {
    let obj = {
      startDate,
      endDate,
    };
    try {
      setIsLoaded(true);
      const response = await axios
        .post(`${url}/leads/filterLeadsByDate`, obj, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              agentName: item.agent_name ? item.agent_name.user_name : "",
              payment : item.payment_status ,
              payment_status: item.payment_status ? (
                <>
                  <span>
                    <Typography
                      style={{
                        borderRadius: "5px",
                        background: `${
                          item.payment_status === "paid" ||
                          item.payment_status === "Paid"
                            ? "green"
                            : item.payment_status === "new" ||
                              item.payment_status === "New"
                            ? "yellow"
                            : "red"
                        }`,
                        color: `${
                          item.payment_status === "new" ||
                          item.payment_status === "New"
                            ? "black"
                            : "white"
                        }`,
                      }}
                    >
                      {item.payment_status}
                    </Typography>
                  </span>
                </>
              ) : (
                ""
              ),
              date: item.date.split("T")[0],
              time: item.date.split("T")[1].split(".")[0],
              action: (
                <>
                  <span
                    onClick={() => navigate(`/mainlead/${item._id}_readOnly`)}
                    title="view"
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  {(item.payment_status != "paid" &&
                    item.payment_status != "Paid") ||
                  userType === "admin" ? (
                    <>
                      {" "}
                      <span
                        onClick={() => navigate(`/mainlead/${item._id}_update`)}
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        title="edit"
                      >
                        <CreateIcon />
                      </span>
                      <span
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {/* <SyncAltIcon /> */}
                      </span>
                      {item.payment_status != "paid" &&
                      item.payment_status != "Paid" ? (
                        <>
                          <span
                            onClick={() => {
                              if (
                                item.client_name &&
                                item.email &&
                                item.phone_number &&
                                item.brand &&
                                item.agent_name
                              ) {
                                navigate(`/convertedlead/${item._id}_CREATE`);
                              } else {
                                console.log("error");
                                setIsOpen(true);
                                setMessage("fill up the required fields");
                                setSeverity("warning");
                                setTimeout(() => {
                                  setIsOpen(false);
                                }, 2000);
                              }
                            }}
                            title="convert"
                            style={{
                              color: `${lightBackground}`,
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <SyncAltIcon />
                          </span>{" "}
                        </>
                      ) : (
                        []
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        });
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };
  const getAllMainLeads = async () => {
    try {
      setIsLoaded(true);
      const response = await axios
        .get(`${url}/leads/getAllMainLeads`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          // let sortedArr = data.data.data.data.sortBy(function (o) {
          //   return new Date(o.date);
          // });
          // console.log("sortedArr", data.data.data.data);

          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              agentName: item.agent_name ? item.agent_name.user_name : "",
              payment_status: item.payment_status ? (
                <>
                  <span>
                    <Typography
                      style={{
                        borderRadius: "5px",
                        background: `${
                          item.payment_status === "paid" ||
                          item.payment_status === "Paid"
                            ? "green"
                            : item.payment_status === "new" ||
                              item.payment_status === "New"
                            ? "yellow"
                            : "red"
                        }`,
                        color: `${
                          item.payment_status === "new" ||
                          item.payment_status === "New"
                            ? "black"
                            : "white"
                        }`,
                      }}
                    >
                      {item.payment_status}
                    </Typography>
                  </span>
                </>
              ) : (
                ""
              ),
              date: item.date.split("T")[0],
              time: item.date.split("T")[1].split(".")[0],
              action: (
                <>
                  <span
                    onClick={() => navigate(`/mainlead/${item._id}_readOnly`)}
                    title="view"
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  {(item.payment_status != "paid" &&
                    item.payment_status != "Paid") ||
                  userType === "admin" ? (
                    <>
                      {" "}
                      <span
                        onClick={() => navigate(`/mainlead/${item._id}_update`)}
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        title="edit"
                      >
                        <CreateIcon />
                      </span>
                      <span
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {/* <SyncAltIcon /> */}
                      </span>
                      {item.payment_status != "paid" &&
                      item.payment_status != "Paid" ? (
                        <>
                          <span
                            onClick={() => {
                              if (
                                item.client_name &&
                                item.email &&
                                item.phone_number &&
                                item.brand &&
                                item.agent_name
                              ) {
                                navigate(`/convertedlead/${item._id}_CREATE`);
                              } else {
                                console.log("error");
                                setIsOpen(true);
                                setMessage("fill up the required fields");
                                setSeverity("warning");
                                setTimeout(() => {
                                  setIsOpen(false);
                                }, 2000);
                              }
                            }}
                            title="convert"
                            style={{
                              color: `${lightBackground}`,
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <SyncAltIcon />
                          </span>{" "}
                        </>
                      ) : (
                        []
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        });
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5" color={`${lightText} `}>
              Main Leads
            </Typography>
          </Grid>

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginTop: "40px",
              // paddingBottom: "20px",
            }}
          >
            {/* <Button
              onClick={getAllMainLeads}
              variant="contained"
              style={{ background: `${lightBackground}`, color: `${white}` }}
            >
              {isLoaded ? (
                <span>
                  {" "}
                  <CircularProgress
                    style={{
                      color: `${white}`,
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </span>
              ) : (
                <>
                  <RefreshIcon sx={{ mr: 1 }} />
                  Reload
                </>
              )}
            </Button> */}

            <Grid container md={6} xs={12}>
              <Grid container item md={3} xs={12} 
              sx={{ p: 1 }}
              >
                <BasicDatePicker
                  label={"Start Date"}
                  value={startDate}
                  setValue={setStartDate}
                  item
                />
              </Grid>
              <Grid container item md={3} xs={12}
               sx={{ p: 1 }}
               >
                <BasicDatePicker
                  label={"End Date"}
                  value={endDate}
                  setValue={setEndDate}
                  item
                />
              </Grid>
              <Grid container item md={4} xs={12}
               sx={{ p: 3 }} 
               >
                <Button
                  item
                  onClick={getFilterMainLeads}
                  variant="contained"
                  fullWidth
                  style={{
                    background: `${lightBackground} `,
                    color: `${white} `,
                    // height: '60%'
                  }}
                >
                  {isLoaded ? (
                    <span>
                      {" "}
                      <CircularProgress
                        style={{
                          color: `${white}`,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </span>
                  ) : (
                    <>
                      <RefreshIcon sx={{ mr: 1 }} />
                      Get Data
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              style={{ display: "flex", justifyItems: "flex-end" }}
              md={6}
              xs={12}
            >
              <Grid item container md={4} xs={0} sx={{ p: 3 }}></Grid>
              <Grid item container md={4} xs={12}
               sx={{ p: 3 }}>
                {userType === "admin" ? (
                  <Button
                    item
                    fullWidth
                    onClick={async () => {
                      setIsSheetLoaded(true);
                      await axios
                        .post(
                          `${url}/leads/dx`,
                          {
                            link: url.split("/a")[0],
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${state.token}`,
                            },
                          }
                        )
                        .then((response) => {
                          console.log(response.data.data.data);
                          window.open(response.data.data.data, "_parent");
                        })
                        .catch((error) => {
                          console.log(error.message);
                        });
                      setIsSheetLoaded(false);
                    }}
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                  >
                    {isSheetLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : (
                      <>
                        <SimCardDownloadIcon sx={{ mr: 1 }} />
                        Excel Sheet
                      </>
                    )}
                  </Button>
                ) : (
                  []
                )}
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 3 }}>
                <Button
                  item
                  fullWidth
                  onClick={() => navigate(`/mainlead/00000000_CREATE`)}
                  style={{
                    marginRight: "5px",
                    background: `${lightBackground}`,
                    color: `${white}`,
                  }}
                >
                  <AddCircleOutlineIcon sx={{ mr: 1 }} />
                  Add new lead
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default MainLeadsList;
